import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environement } from '@environments/environment';
import { MessageService } from 'primeng/api';
import { BaseService } from '@services/base.service';
import {
  CategoryStats,
  ExamByCategory,
  QuestionsByThemes,
  QuestionStats,
  UserExamQuestionsByTheme,
} from '@models/statistics/statistics.model';
import { PageRequest, PageResponse } from 'pf-ui';
import { PageableResponse } from '@models/common/pageable-response.model';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService extends BaseService {
  signalCategoriesStats = signal<CategoryStats[] | null>(null);

  signalFlaggedQuestionsStats = signal<PageResponse<QuestionStats> | undefined>(
    undefined,
  );
  signalQuestionsStats = signal<PageResponse<QuestionStats> | undefined>(
    undefined,
  );

  signalQuestionsByThemes = signal<QuestionsByThemes[] | null>(null);
  signalUserExamQuestionsByThemes = signal<UserExamQuestionsByTheme[] | null>(
    null,
  );
  signalUserExamSessionFinisherByCategory = signal<CategoryStats[] | null>(
    null,
  );
  signalExamByCategory = signal<ExamByCategory | null>(null);

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
  ) {
    super(http, messageService);
  }

  getQuestionsStats(
    event: PageRequest,
  ): Observable<PageResponse<QuestionStats>> {
    const params = this.getHttpParams(event);

    return this.executeRequest(
      this.http.get<PageableResponse<QuestionStats>>(
        `${environement.BACKEND_URL}/statistics/questions-stats`,
        { params },
      ),
    ).pipe(
      this.mapPageableResponseToPageResponse<QuestionStats>(event),
      tap((questionsStats) => {
        this.signalQuestionsStats.set(questionsStats);
      }),
    );
  }

  getCategoryStats(): Observable<CategoryStats[]> {
    return this.executeRequest(
      this.http.get<CategoryStats[]>(
        `${environement.BACKEND_URL}/statistics/categories-stats`,
      ),
    ).pipe(
      tap((categoriesStats) => {
        this.signalCategoriesStats.set(categoriesStats);
      }),
    );
  }

  getFlaggedQuestionsStats(
    event: PageRequest,
  ): Observable<PageResponse<QuestionStats>> {
    const params = this.getHttpParams(event);

    return this.executeRequest(
      this.http.get<PageableResponse<QuestionStats>>(
        `${environement.BACKEND_URL}/statistics/flagged-questions-stats`,
        { params },
      ),
    ).pipe(
      this.mapPageableResponseToPageResponse<QuestionStats>(event),
      tap((questionsStats) => {
        this.signalFlaggedQuestionsStats.set(questionsStats);
      }),
    );
  }

  getQuestionsByThemes(): Observable<QuestionsByThemes[]> {
    return this.executeRequest(
      this.http.get<QuestionsByThemes[]>(
        `${environement.BACKEND_URL}/statistics/questions-by-themes`,
      ),
    ).pipe(
      tap((questionsByThemes) => {
        this.signalQuestionsByThemes.set(questionsByThemes);
      }),
    );
  }

  getUserExamQuestionsByThemes(): Observable<UserExamQuestionsByTheme[]> {
    return this.executeRequest(
      this.http.get<UserExamQuestionsByTheme[]>(
        `${environement.BACKEND_URL}/statistics/user-exam-questions-by-themes`,
      ),
    ).pipe(
      tap((questionsByThemes) => {
        this.signalUserExamQuestionsByThemes.set(questionsByThemes);
      }),
    );
  }

  getUserExamSessionFinisherByCategory(): Observable<CategoryStats[]> {
    return this.executeRequest(
      this.http.get<CategoryStats[]>(
        `${environement.BACKEND_URL}/statistics/categories-stats`,
      ),
    ).pipe(
      tap((userExamSessionFinisherByCategory) => {
        this.signalUserExamSessionFinisherByCategory.set(
          userExamSessionFinisherByCategory,
        );
      }),
    );
  }

  getExamsByCategories(categoryIds: string[]): Observable<ExamByCategory> {
    return this.executeRequest(
      this.http.post<ExamByCategory>(
        `${environement.BACKEND_URL}/statistics/exams-by-categories`,
        categoryIds ?? [],
      ),
    ).pipe(
      tap((examByCategory) => {
        this.signalExamByCategory.set(examByCategory);
      }),
    );
  }
}
